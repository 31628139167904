<template>
  <create-filters :show-language="false">
    <template #secondary>
      <div class="DashboardFiltersV2-ButtonsContainer">
        <dashboard-filters-v2-item>
          <v-button
            class="is-black"
            @click="$emit('create')"
          >
            {{ $t('general.create_newsroom') }}
          </v-button>
        </dashboard-filters-v2-item>
      </div>
    </template>
  </create-filters>
</template>

<script>
import CreateFilters from '@/components/stories/CreateFilters'

/**
 * @module NewsroomFilters
 */
export default {
  name: 'NewsroomFilters',
  components: { CreateFilters }
}
</script>
