<template>
  <v-panel class="NewsroomPanel">
    <template slot="heading">
      <div class="columns is-mobile is-multiline is-aligned-middle">
        <div class="column">
          <a
            :href="editUrl"
            target="hn_newsroom_edit"
            class="is-flex is-aligned-middle"
          >
            <country-flag :country="newsroom.country.iso_alpha2" />
            <span class="has-text-weight-bold">{{ newsroom.name }}</span>
          </a>
        </div>
        <div class="column is-narrow-tablet">
          <div class="columns is-mobile is-aligned-middle">
            <div class="column has-text-weight-bold border-r-grey-lighter">
              <div :class="{'has-text-success': newsroom.is_published, 'has-text-danger':!newsroom.is_published }">
                {{ $t(newsroom.is_published ? 'general.published': 'general.unpublished') }}
              </div>
            </div>
            <div class="column has-text-weight-bold has-nowrap">
              <i18n path="components.newsroom_panel.stories_count">
                <span slot="stories_count">{{ newsroom.stories_count }}</span>
              </i18n>
            </div>
          </div>
        </div>
        <div class="column is-4-mobile is-narrow-tablet has-text-right">
          <v-button
            class="is-black"
            @click="$router.push({ name: 'story.create', query: { newsroomId: newsroom.id } })"
          >
            {{ $t('components.newsroom_panel.create_story') }}
          </v-button>
        </div>
      </div>
    </template>
    <div class="columns is-mobile is-multiline">
      <div class="column is-order-2-mobile is-flex is-column">
        <div class="m-b-m">
          {{ newsroom.description }}
        </div>
        <div class="buttons m-t-a">
          <v-button
            :loading="isDeleting"
            class="is-plain is-primary"
            @click="deleteNewsroom"
          >
            {{ $t('general.delete') }}
          </v-button>
          <v-button
            :disabled="!newsroom.is_published"
            class="is-primary is-outlined"
            @click="$router.push({ name: 'story.newsroom-embed', params: { newsroomId: newsroom.id } })"
          >
            {{ $t('components.newsroom_panel.embed_newsroom') }}
          </v-button>
          <v-button
            :disabled="!newsroom.is_published"
            class="is-black is-outlined"
            data-testid="NewsroomAnalyticsTrigger"
            @click="$router.push({ name: 'story.newsrooms.facts', params: { newsroomId: newsroom.id } })"
          >
            {{ $t('components.newsroom_panel.stats') }}
          </v-button>
          <v-button
            class="is-primary"
            @click="editNewsroom"
          >
            {{ $t('forms.edit') }}
          </v-button>
          <a
            :href="viewUrl"
            target="hn_newsroom_view"
            class="button is-plain is-narrow is-black m-l-a"
          >
            {{ $t('components.newsroom_panel.goto_newsroom') }}
          </a>
        </div>
      </div>
      <div v-if="newsroom.logo.cropped" class="column is-12-mobile is-narrow is-order-1-mobile has-text-centered">
        <a :href="editUrl" target="hn_newsroom_edit">
          <round-avatar :src="newsroom.logo.cropped" :acronym-fallback="newsroom.name" size="xx-large" />
        </a>
      </div>
    </div>
  </v-panel>
</template>

<script>

/**
 * @module NewsroomPanel
 */

export default {
  name: 'NewsroomPanel',
  props: {
    /** @type Newsroom */
    newsroom: {
      type: Object,
      required: true
    },
    isDeleting: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    editUrl () {
      return this.$getUrl(`${this.newsroom.slug}/edit`, 'hypenews', false)
    },
    viewUrl () {
      return this.$getUrl(`${this.newsroom.slug}`, 'hypenews', false)
    }
  },
  methods: {
    async deleteNewsroom () {
      try {
        await this.$prompt(this.$t('components.newsroom_panel.delete_prompt_content', { brand: this.brandName }), this.$t('components.newsroom_panel.delete_prompt_title'), {
          inputPattern: new RegExp(this.$t('general.delete'), 'i'),
          confirmButtonText: this.$t('general.delete'),
          inputErrorMessage: this.$t('components.newsroom_panel.delete_invalid_passphrase'),
          inputValidator: false
        })
        this.$emit('delete', this.newsroom.id)
      } catch (err) { }
    },
    editNewsroom () {
      this.$redirectTo(this.editUrl, 0, 'hn_newsroom_edit')
    }
  }
}
</script>
