<template>
  <page-section
    v-loading="isFetchingNewsrooms"
    class="Newsrooms"
    :page-title="$t('pages.newsrooms.title')"
    :page-tooltip="$t('pages.newsrooms.sidebar_description')"
  >
    <template #above-title>
      <newsroom-filters class="m-b-m" @create="isCreateNewsroomModalVisible=true" />
    </template>
    <template v-if="newsroomsFiltered.length">
      <newsroom-panel
        v-for="newsroom in newsroomsFiltered"
        :key="newsroom.id"
        :newsroom="newsroom"
        :is-deleting="isDeletingNewsroom === newsroom.id"
        class="m-b-m"
        @delete="deleteNewsroom"
      />
    </template>
    <div
      v-else
      class="has-text-weight-bold"
    >
      {{ $t('pages.newsrooms.no_items_found') }}
    </div>
    <create-newsroom-modal
      :visible.sync="isCreateNewsroomModalVisible"
      :newsrooms="newsrooms"
      :is-creating-newsroom="isCreatingNewsroom"
      :brand="activeBrand"
      :user="currentUser"
      @create="handleNewsroomCreation"
    />
  </page-section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import NewsroomPanel from '@/components/stories/NewsroomPanel'
import NewsroomFilters from '@/components/stories/NewsroomFilters'
import CreateNewsroomModal from '@/components/stories/CreateNewsroomModal'

/**
 * @module Newsrooms
 */
export default {
  name: 'Newsrooms',
  components: { CreateNewsroomModal, NewsroomFilters, NewsroomPanel },
  data () {
    return {
      isCreateNewsroomModalVisible: false
    }
  },
  computed: {
    ...mapGetters(['activeBrand', 'currentUser']),
    ...mapGetters('newsroom', ['newsroomsFiltered', 'newsrooms', 'isDeletingNewsroom', 'isFetchingNewsrooms', 'isCreatingNewsroom'])
  },
  methods: {
    ...mapActions('newsroom', ['deleteNewsroom', 'createNewsroom']),
    handleNewsroomCreation (form) {
      return this.createNewsroom(form)
        .then(() => {
          this.isCreateNewsroomModalVisible = false
        })
        .catch((err) => {
          this.$displayRequestError(err)
        })
    }
  }
}
</script>
