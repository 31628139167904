<template>
  <el-dialog
    :visible.sync="visibleLocal"
    :title="$t('components.create_newsroom_modal.title')"
  >
    <div class="m-b-m">
      {{ $t('components.create_newsroom_modal.content') }}
    </div>
    <form-wrapper :validator="$v.form">
      <template v-if="hasNoNewsrooms">
        <form-field
          :label="$t('general.name')"
          name="name"
        >
          <input
            v-model="validator.$model"
            slot-scope="{ validator }"
            :placeholder="$t('')"
            class="input"
            type="text"
          >
        </form-field>
        <form-field
          :label="$t('general.language')"
          name="language"
        >
          <language-picker
            v-model="validator.$model"
            slot-scope="{validator}"
          />
        </form-field>
      </template>
      <form-field
        :label="$t('general.country')"
        name="country"
      >
        <country-picker
          v-model="validator.$model"
          slot-scope="{validator}"
          :exclude="takenCountries"
        />
      </form-field>
    </form-wrapper>
    <v-button
      slot="footer"
      :loading="isCreatingNewsroom"
      class="is-primary"
      @click="createNewsroom"
    >
      {{ $t('general.create_newsroom') }}
    </v-button>
  </el-dialog>
</template>

<script>
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'
import Form from '@/services/forms/Form'
import { required, requiredIf } from 'vuelidate/lib/validators'

/**
 * @module CreateNewsroomModal
 */
export default {
  name: 'CreateNewsroomModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    newsrooms: {
      type: Array,
      required: true
    },
    isCreatingNewsroom: {
      type: Boolean,
      default: false
    },
    brand: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: new Form({
        country: { value: null, rules: { required } },
        name: {
          value: null,
          rules: {
            required: requiredIf(function () {
              return this.hasNoNewsrooms
            })
          }
        },
        language: {
          value: null,
          rules: {
            required: requiredIf(function () {
              return this.hasNoNewsrooms
            })
          }
        }
      })
    }
  },
  validations () {
    return {
      form: this.form.rules()
    }
  },
  computed: {
    visibleLocal: generateComputedSetterWithEmit('visible', 'update:visible'),
    takenCountries () {
      return this.newsrooms.map(n => n.country.iso_alpha2)
    },
    hasNoNewsrooms () {
      return this.newsrooms.length === 0
    }
  },
  watch: {
    visible (isVisible) {
      if (isVisible && this.hasNoNewsrooms) {
        this.form.country = this.brand.country.data.iso_alpha2
        this.form.name = this.brand.name
        this.form.language = this.$TranslationService.currentLanguageNoLocale
      }
      if (!isVisible) {
        this.form.reset()
        this.$v.$reset()
      }
    }
  },
  methods: {
    createNewsroom () {
      this.$v.$touch()
      if (this.$v.$error) return
      const data = {
        brand: this.brand.id,
        country: this.form.country
      }
      if (this.hasNoNewsrooms) {
        data.name = this.form.name
        data.language = this.form.language
      }
      this.$emit('create', data)
    }
  }
}
</script>
